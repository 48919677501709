import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import './Jobs.scss';
import Button from '../../ui/Button';
import IconSmall from '../../ui/IconSmall';
import Scroll from '../../ui/Scroll';
import TitleUp from '../../ui/TitleUp';
import Description from '../../ui/Description';
import gsap from 'gsap';

const Jobs = ({ lang, jobOffers }) => {
    const { t, i18n } = useTranslation("global");

    // State for job offers
    const [jobOffersArray, setJobOffersArray] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // Slider references and state
    const [sliderIndex, setSliderIndex] = useState(0);
    const [cardsInView, setCardsInView] = useState(3);
    const slider = useRef(null);
    const card = useRef(null);

    const [classArrowLeft, setClassArrowLeft] = useState('job-slider__nav job-slider__left hidden');
    const [classArrowRight, setClassArrowRight] = useState('job-slider__nav job-slider__right hidden');

    // Fetch and process job offers
    useEffect(() => {
        setIsLoading(true);
        try {
            if (jobOffers && jobOffers.length > 0) {
                
                const processedOffers = jobOffers.map(job => {
                    let obj = {};
                    //const jobRestric = job.name.substring(0, 3).toLowerCase();

                    obj.name = job.name.toUpperCase();
                    obj.location = job.location;
                    obj.body = job[i18n.language]?.body;

                    obj.image = process.env.REACT_APP_BMG_WEB_URL +'/logos/'+ (job.logo ? job.logo : 'BMG.jpg');

                    // Assign images based on job prefix
                    // switch (jobRestric) {
                    //     case "eba": obj.image = "./img/jobs/EBACE.jpg"; break;
                    //     case "eph": obj.image = "./img/jobs/ephj.jpg"; break;
                    //     case "wat": obj.image = "./img/jobs/W&W.jpg"; break;
                    //     case "wef": obj.image = "./img/jobs/WEF.jpg"; break;
                    //     case "ome": obj.image = "./img/jobs/OMEGA.jpg"; break;
                    //     case "mon": obj.image = "./img/jobs/MONTREUX.jpg"; break;
                    //     case "ath": obj.image = "./img/jobs/ATHLETISSIMA.jpg"; break;
                    //     default: obj.image = "./img/jobs/BMG.jpg";
                    // }
                    return obj;
                });

                setJobOffersArray(processedOffers);
            } else {
                setJobOffersArray([]);
            }
        } catch (err) {
            setError("Failed to load job offers.");
        } finally {
            setIsLoading(false);
        }
    }, [i18n.language, jobOffers]);

    // Handle slider navigation
    const handleNavClick = (direction) => {
        setSliderIndex((prevIndex) => {
            let newIndex = direction === 'left' ? prevIndex - 1 : prevIndex + 1;
    
            // Ensure the new index stays within bounds
            const maxIndex = Math.max(0, jobOffersArray.length - cardsInView);
            newIndex = Math.min(Math.max(newIndex, 0), maxIndex);
    
            // Animate slider movement
            if (slider.current && card.current) {
                gsap.to(slider.current, 0.6, {
                    x: -(card.current.offsetWidth + 16) * newIndex,
                    ease: 'Quad.easeInOut'
                });
            }
    
            return newIndex;
        });
    };

    // Update arrow visibility
    useEffect(() => {
        if (jobOffersArray.length <= cardsInView) {
            setClassArrowLeft("job-slider__nav job-slider__left hidden");
            setClassArrowRight("job-slider__nav job-slider__right hidden");
        } else {
            setClassArrowLeft(sliderIndex === 0 ? "job-slider__nav job-slider__left hidden" : "job-slider__nav job-slider__left");
            setClassArrowRight(sliderIndex >= jobOffersArray.length - cardsInView ? "job-slider__nav job-slider__right hidden" : "job-slider__nav job-slider__right");
        }
    }, [sliderIndex, jobOffersArray.length, cardsInView]);

    // Update cards in view based on screen size
    useEffect(() => {
        const updateCardsInView = () => {
            if (window.innerWidth <= 480) {
                setCardsInView(1);  // Show 1 card per slide on mobile
            } else if (window.innerWidth <= 800) {
                setCardsInView(2);  // Show 2 cards on tablets
            } else {
                setCardsInView(3);  // Default for desktop
            }
        };
    
        updateCardsInView();
        window.addEventListener("resize", updateCardsInView);
    
        return () => window.removeEventListener("resize", updateCardsInView);
    }, []);

    if (isLoading) return <p>Loading job offers...</p>;
    if (error) return <p>{error}</p>;
    if (jobOffersArray.length === 0) return <p>No job offers available at the moment.</p>;

    return (
        <section className='width-100 flex job-wrapper'>
            <div className='constrain-width padding-sides'>
                <TitleUp text={t("jobs.title")} />
                <div className='job-description'>
                    <Description text={t("jobs.description")} />
                </div>
            </div>

            <div className='constrain-width job-slider__wrapper'>
                {/* Left Arrow */}
                <div className={classArrowLeft} onClick={() => handleNavClick('left')}>
                    
                    <p><i class="job-arrow job-left"></i></p>
                    <button className='nav-button'></button>
                </div>

                {/* Job Slider */}
                <div className='job-slider'>
                    <div className='job-slider_content' ref={slider}>
                        {jobOffersArray.map((job, index) => (
                            <div className="job-card" key={index} ref={card}>
                                <img src={job.image} alt={'Be My Guest - ' + job.name} title={'Be My Guest - ' + job.name} />
                                <div className='job-card_content'>
                                    <h3 className='text-dark'>{job.name}</h3>
                                    <p>{job.location}</p>
                                    <h5>{job.body}</h5>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Right Arrow */}
                <div className={classArrowRight} onClick={() => handleNavClick('right')}>
                    
                    <p><i class="job-arrow job-right"></i></p>
                    <button className='nav-button'></button>
                </div>
            </div>

            {/* CTA Section */}
            <div className='job-cta padding-sides'>
                <IconSmall title={t("jobs.tagline")} description="" icon="download.svg" alignement="left" theme="accent" />
                <Button theme="white" title={t("jobs.button")} animate={true} to="#app" />
            </div>

            <img className='triangle-top' src='img/triangle.svg' alt='' />
            <img className='triangle-bottom' src='img/triangle.svg' alt='' />
        </section>
    );
};

export default Jobs;